import { dedicatedHRQuestion, phoneRegex } from '@/utils/formHelper'
import { z } from 'zod'

export const formSchema = z
  .object({
    full_name: z
      .string()
      .trim()
      .min(1, 'demo_form.full_name_error_required_message')
      .max(50, 'demo_form.full_name_error_required_message')
      .refine(
        val => val.trim().length > 0,
        'demo_form.full_name_error_required_message'
      ),
    company_name: z.string(),
    number_of_employees: z
      .string()
      .min(1, 'demo_form.number_of_employee_error_required_message')
      .max(50),
    job_title: z.string(),
    phone_number: z
      .string()
      .min(1, 'demo_form.phone_number_error_required_message')
      .regex(phoneRegex, 'demo_form.phone_number_error_valid_message'),
    email: z
      .string()
      .min(1, 'demo_form.email_address_error_required_message')
      .max(50)
      .email('demo_form.label.invalid_email'),
    phone_code: z.string(),
    do_you_have_hr_specialized_employee__1: z.optional(z.string().or(z.null())),
  })
  .refine(
    data =>
      !dedicatedHRQuestion.includes(data.number_of_employees) ||
      (dedicatedHRQuestion.includes(data.number_of_employees) &&
        !!data.do_you_have_hr_specialized_employee__1),
    {
      message: 'demo_form.label.please_select_an_answer',
      path: ['do_you_have_hr_specialized_employee__1'],
    }
  )

export type TFormSchema = z.infer<typeof formSchema>

export type TCountry = {
  name_en: string
  name_ar: string
  phone_code: string
  country_code: string
  flag?: string
}

export type TFromAPIBody = {
  firstname: string
  lastname: string
  phone: string
  email: string
  jobtitle: string
  numemployees?: string
  do_you_have_hr_specialized_employee__1?: string
  company: string
}

export type Method =
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'options'
  | 'OPTIONS'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
