'use client'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslations } from 'next-intl'
import clsx from 'clsx'
import { useMutation } from '@tanstack/react-query'
import { zodResolver } from '@hookform/resolvers/zod'
import { dedicatedHRQuestion, submitForm } from '@/utils/formHelper'
import { getCookie } from '@/utils/getCookie'
import useDemoFormHandler from '@/utils/useDemoFormHandler'
import SelectInputField from '@/ds/atoms/InputField/SelectInputField'
import { rangesOFEmployees } from './helper'
import InputField from '@/ds/atoms/InputField/InputField'
import { TFromAPIBody, TFormSchema, formSchema } from './type'
import { analytics } from '@/integration/analytics'
import { Button } from '@/ds/atoms/Button'
import { StrapiLinkComponent } from '@/types/strapiComponents'
import CountrySelect from './SelectCountry'

const DemoFrom = ({
  formId,
  setShowThanksBox,
  buttonId,
  submitButtonText,
  demoFormTncText,
  tncLink,
}: {
  setShowThanksBox: Dispatch<SetStateAction<boolean>>
  formId: string
  buttonId: string
  submitButtonText?: string
  demoFormTncText?: string
  tncLink?: StrapiLinkComponent
}): ReactNode => {
  const t = useTranslations()
  const { UTMParams, isAlreadySubmit, setIsAlreadySubmit } =
    useDemoFormHandler()

  const labelStyle = 'text-xs md:text-base text-grays-lighter font-normal'
  // React Hook Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm<TFormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: { phone_code: '+966' },
  })
  const numberOfEmployeesWatcher = watch('number_of_employees')

  // react-query
  const submitFormMutation = useMutation({
    mutationFn: submitForm,
    onError: (error, formSubmitData) => {
      if (error.message.includes('Form is already submitted')) {
        setIsAlreadySubmit(true)
      } else {
        analytics.track(`form_submit_failure`, {
          id: formId,
          email: formSubmitData?.form_submit?.data?.email,
          numemployees: formSubmitData?.form_submit?.data?.numemployees,
          error: error?.message,
        })
      }
    },
    onSuccess: (_, formSubmitData) => {
      analytics.track(`form_submit_success`, {
        id: formId,
        email: formSubmitData?.form_submit?.data?.email,
        numemployees: formSubmitData?.form_submit?.data?.numemployees,
      })
      reset()
      setShowThanksBox(true)
    },
  })

  // method
  const handleSubmitForm = async (data: TFormSchema) => {
    formSchema.parse(data)

    let spaceIndex = data?.full_name?.indexOf(' ')
    const haveLastName = spaceIndex > 0
    const bodyData: TFromAPIBody = {
      firstname: haveLastName
        ? data?.full_name.substring(0, spaceIndex)
        : data?.full_name,
      lastname: haveLastName ? data?.full_name.substring(spaceIndex + 1) : '',
      phone: `${data?.phone_code + data?.phone_number}`,
      email: data.email,
      jobtitle: data.job_title,
      company: data.company_name,
    }
    if (data.number_of_employees) {
      bodyData.numemployees = data.number_of_employees
    }

    if (
      data.do_you_have_hr_specialized_employee__1 &&
      dedicatedHRQuestion.includes(data.number_of_employees)
    ) {
      bodyData.do_you_have_hr_specialized_employee__1 =
        data.do_you_have_hr_specialized_employee__1
    }
    const formBody: Record<string, unknown> = {
      form_key: 'registration_form_variant_1',
      data: bodyData,
      metadata: {
        context: {
          pageUri: window.location.href,
          pageName: document.title,
          hutk: getCookie('hubspotutk'),
        },
        ...(UTMParams && { utm_info: UTMParams }),
      },
    }
    const body = {
      form_submit: formBody,
    }

    submitFormMutation.mutate(body)
  }

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      id={formId}
    >
      <div className="flex flex-col gap-4 text-grays-lighter">
        <div className="row-1 flex gap-2 flex-wrap md:flex-nowrap">
          <div className=" w-full md:w-2/4 h-fit">
            <InputField
              register={register('full_name')}
              errors={errors}
              field_name="full_name"
              placeholder={t('demo_form.full_name')}
              data-testid="full_name"
            />
          </div>
          <div className="w-full md:w-2/4">
            <InputField
              register={register('company_name')}
              errors={errors}
              field_name="company_name"
              placeholder={t('demo_form.company_name')}
              data-testid="company_name"
            />
          </div>
        </div>
        <div className="row-1 flex gap-2 flex-wrap md:flex-nowrap">
          <div className=" w-full md:w-2/4 h-fit">
            <SelectInputField
              register={register('number_of_employees')}
              field_name="number_of_employees"
              errors={errors}
              list={rangesOFEmployees}
              data-testid="number_of_employees"
            />
          </div>
          <div className="w-full md:w-2/4">
            <InputField
              register={register('job_title')}
              errors={errors}
              field_name="job_title"
              placeholder={t('demo_form.label.job_title')}
              data-testid="job_title"
            />
          </div>
        </div>
        {dedicatedHRQuestion.some(range =>
          (numberOfEmployeesWatcher ?? []).includes(range)
        ) && (
          <div className="row-1 flex gap-2 flex-wrap md:flex-nowrap">
            <div className="md:col-span-2">
              <label className={labelStyle}>
                {t('demo_form.do_you_have_hr_specialized_employee__1')}
              </label>
              <div className="flex gap-3">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    {...register('do_you_have_hr_specialized_employee__1')}
                    value="Yes"
                    id="yes"
                  />
                  <label
                    htmlFor="yes"
                    className={labelStyle}
                  >
                    {t('demo_form.label.yes')}
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    {...register('do_you_have_hr_specialized_employee__1')}
                    value="No"
                    id="no"
                  />
                  <label
                    htmlFor="no"
                    className={labelStyle}
                  >
                    {t('demo_form.no')}
                  </label>
                </div>
              </div>
              {errors.do_you_have_hr_specialized_employee__1 && (
                <span className="error-input-message">
                  {`${t(
                    errors.do_you_have_hr_specialized_employee__1.message as any
                  )}`}
                </span>
              )}
            </div>
          </div>
        )}
        <div className="row-1 flex gap-2 flex-wrap md:flex-nowrap">
          <div className="w-full md:w-2/4 h-fit flex flex-wrap">
            <div
              className={clsx(
                'phone-border',
                errors.phone_number && 'input-error-border'
              )}
            >
              <CountrySelect
                onChange={phone_code => setValue('phone_code', phone_code)}
              />
              <input
                type="text"
                {...register('phone_number')}
                className="w-3/5 py-4 px-5 text-grays-lighter rounded-lg border-0  outline-none"
                placeholder={t('demo_form.phone_number')}
                data-testid="phone_number"
              />
            </div>
            {errors.phone_number && (
              <span
                className=" w-full error-input-message"
                data-testid="phone_number_error_message"
              >
                {`${t(
                  (errors?.phone_number?.message as any) ||
                    'phone_number_error_valid_message'
                )}`}
              </span>
            )}
          </div>
          <div className="w-full md:w-2/4">
            <InputField
              register={register('email')}
              errors={errors}
              field_name="email"
              placeholder={t('demo_form.email_address')}
              data-testid="email"
            />
          </div>
        </div>
      </div>
      <div className=" flex flex-col form-footer mt-5 md:mt-[32px] gap-3">
        <span className="block text-xs text-grays-lighter font-normal mt-4 md:mt-0">
          {demoFormTncText || t('demo_form.by_clicking_request_a_free_demo')}
          &nbsp;
          <Button
            as="a"
            variant="outlined"
            color="white"
            {...(tncLink?.link && { id: tncLink?.linkId })}
            href={tncLink?.link || '/privacy'}
            target={tncLink?.target || '_blank'}
            className="font-normal border-none border-b border-grays-lighter text-grays-lighter text-xs p-0 underline"
            label={tncLink?.text || t('demo_form.JISR_terms_of_Service')}
          />
        </span>
        {submitButtonText ? (
          <Button
            withIcon
            as="button"
            type="submit"
            className="w-fit min-w-[160px] justify-between"
            data-testid="request_free_demo"
            disabled={isSubmitting && submitFormMutation.isPending}
            id={buttonId}
            label={submitButtonText}
          />
        ) : (
          <button
            data-testid="request_free_demo"
            disabled={isSubmitting && submitFormMutation.isPending}
            type="submit"
            className=" btn-gradient rounded-[10rem] font-semibold gap-4  px-4 py-3 md:py-3 md:px-8  flex justify-between items-center w-fit"
          >
            {t('label.request_a_free_demo')}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="arrow-rotate-ar"
            >
              <path
                d="M15.4454 10.7678L12.9264 10.7236L12.9264 4.448L2.18718 15.1872L0.419417 13.4194L11.1586 2.68023L4.88303 2.68023L4.83883 0.161165H15.4454V10.7678Z"
                fill="CurrentColor"
              ></path>
            </svg>
          </button>
        )}
        {isAlreadySubmit && (
          <p
            className="mt-0 m:mt-4 text-[var(--ds-core-color-error-700)] error-input-message"
            data-testid="form_is_already_submitted_message"
          >
            {t('demo_form.form_is_already_submitted')}
            <Button
              as="a"
              variant="outlined"
              color="white"
              href="/contact"
              className="text-blue-800"
              label={` ${t('demo_form.contact_us')}`}
            />
          </p>
        )}
        {submitFormMutation.isError && !isAlreadySubmit && (
          <p
            className="mt-0 m:mt-4 text-[var(--ds-core-color-error-700)] error-input-message"
            data-testid="something_went_wrong_message"
          >
            {t('demo_form.something_went_wrong')}
          </p>
        )}
        {submitFormMutation.isSuccess && (
          <p
            data-testid="success_message"
            className="mt-0 m:mt-4 text-[var(--ds-core-color-success-700)]"
          >
            {t('demo_form.thanks_message_1')}
          </p>
        )}
      </div>
    </form>
  )
}

export default DemoFrom
