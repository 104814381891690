import React from 'react'
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form'
import clsx from 'clsx'
import { useLocale, useTranslations } from 'next-intl'

type InputProps = {
  register: UseFormRegisterReturn
  type?: string
  className?: string
  errors: FieldErrors | undefined
  field_name: string
  placeholder?: string
  ref?: React.Ref<HTMLInputElement>
  hasSARIndicator?: boolean
  value?: string
  min?: number | string
  max?: number | string
} & React.InputHTMLAttributes<HTMLInputElement>

const InputField: React.FC<InputProps> = ({
  register,
  type,
  className,
  errors,
  field_name,
  placeholder,
  value,
  min,
  max,
  hasSARIndicator = false,
  ...restProps
}) => {
  const t = useTranslations()
  const locale = useLocale()

  return (
    <div className="relative">
      <input
        {...restProps}
        {...register}
        value={value}
        min={min}
        max={max}
        type={type ?? 'text'}
        className={clsx(
          'input-form placeholder:!text-grays-lighter text-grays-lighter',
          {
            'input-error-border': errors?.[field_name],
            'no-spinner': hasSARIndicator,
            '!pr-12': locale !== 'ar' && hasSARIndicator,
            '!pl-[72px]': locale === 'ar' && hasSARIndicator,
          },
          className
        )}
        placeholder={placeholder}
      />
      {errors?.[field_name] && (
        <span
          className="error-input-message"
          data-testid={`${field_name}_error_message`}
        >
          {`${t((errors?.[field_name]?.message as any) ?? '')}`}
        </span>
      )}
      {hasSARIndicator && (
        <span
          className={clsx('absolute -mt-2 text-sm text-grays-lighter', {
            'left-5': locale === 'ar',
            'right-5': locale !== 'ar',
            'top-7': errors?.[field_name],
            'top-1/2': !errors?.[field_name],
          })}
        >
          {t('currency.sar')}
        </span>
      )}
    </div>
  )
}

export default InputField
