import countries from '@/utils/countries.json'
import { TCountry } from '@/components/FormComponents/DemoForm/type'
import useAnalyticVariable from './useAnalyticVariable'
import { useState } from 'react'

const useMCITDemoForm = (): {
  isErrorSaudiNumber: boolean
  selectedCountry: TCountry | undefined
  handleCountryChange: (event: any) => void
  locale: string
  setIsErrorSaudiNumber: React.Dispatch<React.SetStateAction<boolean>>
  UTMParams: Record<string, string | number> | null
  isAlreadySubmit: boolean
  setIsAlreadySubmit: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const { UTMParams, locale } = useAnalyticVariable()
  const [isErrorSaudiNumber, setIsErrorSaudiNumber] = useState(false)
  const [isAlreadySubmit, setIsAlreadySubmit] = useState(false)

  const [selectedCountry, setSelectedCountry] = useState<TCountry | undefined>({
    name_en: 'Saudi Arabia',
    name_ar: 'المملكة العربية السعودية',
    phone_code: '+966',
    country_code: 'SA',
  })

  // method
  const handleCountryChange = (event: any) => {
    const selectedPhoneCode = event.target.value

    const selectedCountry = countries.find(
      country => country.phone_code === selectedPhoneCode
    )
    setSelectedCountry(selectedCountry)
  }

  return {
    isErrorSaudiNumber,
    selectedCountry,
    handleCountryChange,
    locale,
    setIsErrorSaudiNumber,
    UTMParams,
    isAlreadySubmit,
    setIsAlreadySubmit,
  }
}

export default useMCITDemoForm
