import React from 'react'
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form'
import { useLocale, useTranslations } from 'next-intl'
import clsx from 'clsx'

type SelectInputFieldProps = {
  register: UseFormRegisterReturn
  className?: string
  errors: FieldErrors | undefined
  field_name: string
  list: { label: string; value: string }[]
}

const SelectInputField: React.FC<SelectInputFieldProps> = ({
  register,
  errors,
  field_name,
  list,
  ...restProps
}) => {
  const t = useTranslations()
  const locale = useLocale()

  return (
    <>
      <select
        {...restProps}
        {...register}
        className={clsx(
          'input-form placeholder:text-grays-lighter text-grays-lighter',
          {
            'input-error-border': errors?.[field_name],
            '!pr-12': locale !== 'ar',
            '!pl-12': locale === 'ar',
          },
          restProps?.className
        )}
        data-testid={field_name}
      >
        {list?.map(item => (
          <option
            key={item.label}
            value={item.value}
          >
            {t(item.label as any)}
          </option>
        ))}
      </select>
      {errors?.[field_name] && (
        <span
          className="error-input-message"
          data-testid={`${field_name}_error_message`}
        >
          {`${t((errors?.[field_name]?.message as any) ?? '')}`}
        </span>
      )}
    </>
  )
}

export default SelectInputField
