export const rangesOFEmployees = [
  {
    label: 'demo_form.number_of_employees',
    value: '',
  },
  {
    label: 'demo_form.1-20',
    value: '1-20',
  },
  {
    label: 'demo_form.21-50',
    value: '21-50',
  },
  {
    label: 'demo_form.51-100',
    value: '51-100',
  },
  {
    label: 'demo_form.101-250',
    value: '101-250',
  },
  {
    label: 'demo_form.more_than_250',
    value: '501',
  },
]
