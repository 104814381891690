import { useState } from 'react'
import countries from '@/utils/countries.json'
import { useLocale } from 'next-intl'

export default function CountrySelect({
  onChange,
}: {
  onChange: (code: string) => void
}) {
  const [selectedCode, setSelectedCode] = useState('+966')
  const locale = useLocale()

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCode(event.target.value)
    onChange(event.target.value)
  }

  return (
    <select
      id="country-select"
      value={selectedCode}
      onChange={handleChange}
      data-testid="phone_code"
      aria-label="phone_code"
      className="text-custom-gray-200 w-2/5 py-4 px-5 bg-custom-light-200 border-0 rounded-lg outline-none"
    >
      {countries.map((country, index) => {
        const name = locale === 'en' ? country.name_en : country.name_ar
        return (
          <option
            key={`${country.phone_code}-${index}`}
            value={country.phone_code}
          >
            {country.phone_code === selectedCode
              ? country.flag + ' ' + selectedCode
              : country.flag + ' ' + name}
          </option>
        )
      })}
    </select>
  )
}
