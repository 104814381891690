import { strapiBaseUrl, strapiToken } from '@/constants'
import axios from 'axios'
import qs from 'qs'
import { setupCache } from 'axios-cache-interceptor'

if (!strapiBaseUrl) {
  throw new Error('STRAPI_API_URL is not defined in environment variables')
}

if (!strapiToken) {
  throw new Error('STRAPI_API_TOKEN is not defined in environment variables')
}

export const strapiNonCachedInstance = axios.create({
  baseURL: strapiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${strapiToken}`,
  },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { encodeValuesOnly: true }),
  },
})

const strapiCachedInstance = axios.create({
  baseURL: strapiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${strapiToken}`,
  },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { encodeValuesOnly: true }),
  },
})

export const strapiApi = setupCache(strapiCachedInstance)

// Function to handle error logging
const logError = (error: any) => {
  if (error.response) {
    const errorData = {
      status: error.response.status,
      data: error.response.data,
      endpoint: error.config?.url,
    }
    console.error('Strapi API Error:', errorData)
    throw new Error(`Strapi API Error: ${JSON.stringify(errorData)}`)
  } else if (error.request) {
    console.error('No response received:', error.request)
    throw new Error(`Strapi API No response received: ${error.request} | ${error.message}`)
  } else {
    console.error('Error setting up request:', error.message)
    throw new Error(`Strapi API Error setting up request: ${error.message}`)
  }
}

// Response interceptor for error handling
strapiNonCachedInstance.interceptors.response.use(
  response => response,
  error => {
    logError(error) // Use the new logging function
    return Promise.reject(error)
  }
)

strapiApi.interceptors.response.use(
  response => response,
  error => {
    logError(error) // Use the new logging function
    return Promise.reject(error)
  }
)

// Helper function to fetch data from Strapi
export async function fetchFromStrapi<T>(
  endpoint: string,
  options: {
    query?: Record<string, any>
    locale?: string
    preview?: boolean
  } = {}
): Promise<T> {
  const { query = {}, locale, preview } = options

  // Construct query parameters
  const queryParams = {
    ...query,
    ...(locale && { locale }),
    ...{ status: preview ? 'draft' : 'published' },
  }

  const queryString = qs.stringify(queryParams, { encodeValuesOnly: true })
  const fullEndpoint = `/api${endpoint}${queryString ? `?${queryString}` : ''}`

  const response = await strapiApi.get(fullEndpoint)
  return response.data
}

// Types for API responses
export interface StrapiResponse<T> {
  data: {
    id: number
    attributes: T
  }[]
  meta: {
    pagination: {
      page: number
      pageSize: number
      pageCount: number
      total: number
    }
  }
}

export interface StrapiSingleResponse<T> {
  data: {
    id: number
    attributes: T
  }
}

export const fetchJisrBackendAPI = async <ReturnType>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  path: string,
  guest_id_jisr: string | null = null,
  body: Record<string, string | unknown> | null = null
): Promise<ReturnType> => {
  const api = process.env.NEXT_PUBLIC_API_VERSION_ONE
  return await fetch(`${api + path}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(guest_id_jisr && { 'Guest-Id': guest_id_jisr }),
    },
    body: body ? JSON.stringify(body) : null,
  })
    .then(async res => {
      const response = await res.json()
      if (!response) {
        throw new Error('No response from the server')
      }
      if (!res.ok) {
        throw new Error(response.error)
      }
      return response.data
    })
    .catch(error => {
      console.error(`Failed to fetch ${path}`)
      throw new Error(`Failed to fetch ${path} | ${error.message}`)
    })
}
