import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'

const commonPlugins = [
  googleTagManager({
    containerId: process.env.NEXT_PUBLIC_GTM_KEY,
  }),
]

export const analytics = Analytics({
  app: 'jisr-home',
  version: '0.1',
  plugins: commonPlugins,
})

type AnalyticsTrackType = {
  event_name: string
  properties?: Record<string, unknown>
}

export const analyticsTrack = ({
  event_name,
  properties,
}: AnalyticsTrackType) => {
  analytics.track(`${event_name}_landing_page`, properties || {})
}
